<template>
    <kurcc-data-table-page-template :data-table-title="$vuetify.lang.t('$vuetify.pages.groups.groupList')"
                                    :defaultItem="defaultItem"
                                    :delete-dialog-title="$vuetify.lang.t('$vuetify.pages.groups.deleteDialogText')"
                                    :headers="headers"
                                    :toolbar-button-dialog-title="$vuetify.lang.t('$vuetify.pages.groups.newGroup')"
                                    :toolbar-button-text="$vuetify.lang.t('$vuetify.pages.groups.newGroup')"
                                    :update-dialog-title="$vuetify.lang.t('$vuetify.pages.groups.editGroup')"
                                    actionsSuffix="Group"
                                    show-select>
        <template v-slot:dialog="{item}">
            <kurcc-groups-dialog :item="item"/>
        </template>
    </kurcc-data-table-page-template>
</template>

<script>
import Group from "@/modules/groups/models/group";

export default {
    name: "KurccGroupsPage",
    components: {
        KurccDataTablePageTemplate: () => import('@/modules/app/templates/KurccDataTablePageTemplate'),
        KurccGroupsDialog: () => import('@/modules/groups/components/KurccGroupsDialog'),
    },
    data() {
        return {
            headers: [
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.groups.dataTableHeaders.groupName'),
                    value: 'name',
                    align: 'start',
                },
                {text: this.$vuetify.lang.t('$vuetify.general.status'), value: 'enabled'},
                {text: this.$vuetify.lang.t('$vuetify.general.createdAt'), value: 'created_at'},
                {text: this.$vuetify.lang.t('$vuetify.general.actions'), value: 'actions', sortable: false},
            ],
            defaultItem: new Group(),
        }
    },
}
</script>
